
import React from 'react';
import './Buttons.css';

const LinkButton = ({ link, children }) => {
    return (
      <button className='link-button'onClick={() => {
        window.open(link, "_blank", "noreferrer");
      }}>
        {children}
      </button>
    );
  };
  
  export default LinkButton;